import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';

const QuantityField = ({ onChange }) => {
  const [quantity, setQuantity] = useState(6);

  const handleIncrement = () => {
    const newQuantity = quantity + 1;
    setQuantity(newQuantity);
    if (onChange) onChange(newQuantity); // Notifica al padre
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      if (onChange) onChange(newQuantity); // Notifica al padre
    }
  };

  return (
    <TextField
      label="Duración parada en minutos"
      value={quantity === 0 ? '' : quantity}  // Permite que sea vacío
      onChange={(e) => {
        const newValue = e.target.value === '' ? '' : parseInt(e.target.value, 10);
        if (newValue >= 1 || newValue === '') {  // Solo permite valores numéricos mayores o vacío
          setQuantity(newValue);
          if (onChange) onChange(newValue); // Notifica al padre
        }
      }}
      type="number"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleDecrement}>
              <Remove />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleIncrement}>
              <Add />
            </IconButton>
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
};

export default QuantityField;
