import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

//import Dialog from '@material-ui/core/Dialog';
//import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
//import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
// import Slide from '@material-ui/core/Slide';
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
//import TextField from '@material-ui/core/TextField';

// import tacografoDV3BPDF from 'https://staticcontent.inelcan.com/pdf/TACOG_-_DV3B_Frontal.pdf';
// import IbuttonZumbadorFMB120PDF from 'https://staticcontent.inelcan.com/pdf/containers/img/Ibutton-zumbador-con-interruptor-de-seguridad-FMB120.pdf';
// import PiñaDelEBSPDF from 'https://staticcontent.inelcan.com/pdf/Piña-del-EBS.pdf';
// import DescargaRemotaFMB640PDF from 'https://staticcontent.inelcan.com/pdf/Descarga-remota-trasera-FMB640.pdf';
// import ImpresionTicketTachoPDF from 'https://staticcontent.inelcan.com/pdf/Impresión-de-ticket-de-tacógrafo.pdf';
// import CorteRemotoFMB120PDF from 'https://staticcontent.inelcan.com/pdf/Corte-remoto-interruptor-de-seguridad-FMB120.pdf';
// import CorteArranqueFMB120PDF from 'https://staticcontent.inelcan.com/pdf/Corte-de-arranque-con-interruptor-de-seguridad-FMB120.pdf';
// import CorteArranqueFMB640PDF from 'https://staticcontent.inelcan.com/pdf/Corte-de-arranque-con-interruptor-de-seguridad-FMB640.pdf';
// import CorteArranqueLlaveFMB640PDF from 'https://staticcontent.inelcan.com/pdf/Corte-de-arranque-con-llave-de-seguridad-FMB640.pdf';


const listImages = [
  { id: 1, value: null, title: "FMB640", text: "Corte de arranque con interruptor de seguridad.", pdf: "https://staticcontent.inelcan.com/pdf/Corte-de-arranque-con-interruptor-de-seguridad-FMB640.pdf" },
  { id: 2, value: null, title: "FMB120", text: "Corte de arranque con interruptor de seguridad.", pdf: "https://staticcontent.inelcan.com/pdf/Corte-de-arranque-con-interruptor-de-seguridad-FMB120.pdf" },
  { id: 3, value: null, title: "FMB640", text: "Descarga remota trasera", pdf: "https://staticcontent.inelcan.com/pdf/Descarga-remota-trasera-FMB640.pdf" },
  { id: 4, value: null, title: "FMB120", text: "IButton zumbador con interruptor de seguridad", pdf: "https://staticcontent.inelcan.com/pdf/Ibutton-zumbador-con-interruptor-de-seguridad-FMB120.pdf" },
  { id: 5, value: null, title: "Tacógrafo", text: "Impresión del ticket de tacógrafo", pdf: "https://staticcontent.inelcan.com/pdf/Impresión-de-ticket-de-tacógrafo.pdf" },
  { id: 6, value: null, title: "FMB120", text: "Corte remoto - interruptor de seguridad", pdf: "https://staticcontent.inelcan.com/pdf/Corte-remoto-interruptor-de-seguridad-FMB120.pdf" },
  { id: 7, value: null, title: "EBS", text: "Piña del EBS", pdf: "https://staticcontent.inelcan.com/pdf/Piña-del-EBS.pdf" },
  { id: 8, value: null, title: "Tacografo DV3B", text: "Tacografo DV3B frontal", pdf: "https://staticcontent.inelcan.com/pdf/TACOG_-_DV3B_Frontal.pdf" },
  { id: 9, value: null, title: "FMB640", text: "Corte de arranque con llave de seguridad.", pdf: "https://staticcontent.inelcan.com/pdf/Corte-de-arranque-con-llave-de-seguridad-FMB640.pdf" },
];
//https://eu-west-1.console.aws.amazon.com/s3/object/staticcontent.inelcan.com?region=eu-west-1&bucketType=general&prefix=pdf/Pi%C3%B1a-del-EBS.pdf
//https://staticcontent.inelcan.com/pdf/TACOG_-_Piña-del-EBS.pdf
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  rootCard: {
    maxWidth: 345,
    marginTop: 20
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });


const ManagementManuals = () => {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);
  // const [openDialogModify, setOpenDialogModify] = React.useState(false);
  // const [openDialogDelete, setOpenDialogDelete] = React.useState(false);
  // const [selectedManual, setSelectedManual] = React.useState({ title: "" });

  // const ModifyManual = (item) => {
  //   setSelectedManual(item);
  //   setOpenDialogModify(true);
  // }

  // const deleteManual = (item) => {
  //   setSelectedManual(item);
  //   setOpenDialogDelete(true);
  // }

  const clickHandle = (item) => {
    //window.location.href = item.pdf
    window.open(item.pdf, '_blank', 'noreferrer');
    // setSelectedManual(item);
    // setOpen(true);
  }

  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleCloseDialogModify = () => {
  //   setOpenDialogModify(false);
  // };
  // const handleCloseDialogDelete = () => {
  //   setOpenDialogDelete(false);
  // };

  return (
    <Container maxWidth="lg" >
      <Typography style={{ marginTop: 20 }} gutterBottom variant="h4" component="h3">
        Listado de manuales
      </Typography>
      <Grid container className={classes.root} spacing={0}>
        {listImages.map((item) => (
          <Grid key={item.id} item lg={4} md={4} sm={12} style={{ marginBottom: 20 }}>
            <Card className={classes.rootCard}>
              <CardActionArea rel="noopener noreferrer" onClick={() => clickHandle(item)}>
                {/* <CardMedia

                  component="img"
                  alt="Contemplative Reptile"
                  height="260"
                  image={item.value}
                  title="Contemplative Reptile"
                /> */}
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {item.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    {item.text}
                  </Typography>
                </CardContent>
              </CardActionArea>
              {/* <CardActions>
                <Button onClick={() => ModifyManual(item)} size="small" color={"primary"}>
                  Modificar
                </Button>
                <Button onClick={() => deleteManual(item)} size="small" color={"primary"}>
                  Eliminar
                </Button>
              </CardActions> */}
            </Card>
          </Grid>
        ))}
      </Grid>
      {/* <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {selectedManual != null ? selectedManual.title : ""}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container fixed style={{ marginTop: 60, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <img style={{ display: "block", margin: "auto", width: "100%", height: "100%" }} src={selectedManual !== null ? selectedManual.value : ""} alt="CorteArranqueFMB640" />
        </Container>
      </Dialog> */}
      {/* <Dialog open={openDialogModify} onClose={handleCloseDialogModify} TransitionComponent={Transition}>
        <DialogTitle id="responsive-dialog-title">{selectedManual !== null ? "Modificación de el manual: " + selectedManual.title + " " + selectedManual.text : null}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione el nuevo manual.

            <TextField style={{ marginTop: "20px" }} type="file" variant='outlined'
            >

            </TextField>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogModify} autoFocus color="primary">
            Cancelar
          </Button>
          <Button autoFocus color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDialogDelete} onClose={handleCloseDialogDelete} TransitionComponent={Transition}>
        <DialogTitle id="responsive-dialog-title">{selectedManual !== null ? "Eliminación de el manual: " + selectedManual.title + " " + selectedManual.text : null}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Seguro que desea eliminar el manual seleccionado?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogDelete} autoFocus color="primary">
            Cancelar
          </Button>
          <Button autoFocus color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog> */}
    </Container>

  )
}

export default ManagementManuals

