
import React, { useRef, useEffect, useState } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Polygon, Marker, InfoWindow } from "react-google-maps";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';


const { MarkerClusterer } = require("react-google-maps/lib/components/addons/MarkerClusterer");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MapComponent = withScriptjs(
  withGoogleMap((props) => {
    //console.log(props.zoom);
    const mapRef = useRef(null);
    const [selectedPolygon, setSelectedPolygon] = useState(null);
    const { onPolygonUpdate } = props;
    const polygonRef = useRef(null); // Referencia al polígono
    const [polygonPaths, setPolygonPaths] = useState([]);
    const [mapCenter, setMapCenter] = useState(
      props.selectedStop
        ? { lat: props.selectedStop.latitud, lng: props.selectedStop.longitud }
        : { lat: 28.09975, lng: -15.41343 }
    );
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleSnackbarOpen = (message) => {
      setSnackbarMessage(message);
      setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };

    // const bounds = {
    //   north: 85,  // latitud máxima
    //   south: -85,  // latitud mínima
    //   east: -180,  // longitud máxima
    //   west: 180,  // longitud mínima
    // };
    const mapOptions = {
      streetViewControl: false,
      // restriction: {
      //   latLngBounds: bounds,
      // },
      strictBounds: false,
      minZoom: 3,
      disableDefaultUI: false,
      zoomControl: true,
      gestureHandling: "greedy", // Desactiva el gesto táctil
      scrollwheel: true, // Permite zoom con la rueda del ratón
      draggable: true,
    };

    const generatePolygonCoordinates = (center, radius) => {
      const coordinates = [];

      const latOffset = radius / 111320;
      const lngOffset = radius / (111320 * Math.cos(center.lat * (Math.PI / 180)));

      coordinates.push({ lat: center.lat + latOffset, lng: center.lng - lngOffset }); // Noroeste
      coordinates.push({ lat: center.lat + latOffset, lng: center.lng + lngOffset }); // Noreste
      coordinates.push({ lat: center.lat - latOffset, lng: center.lng + lngOffset }); // Sureste
      coordinates.push({ lat: center.lat - latOffset, lng: center.lng - lngOffset }); // Suroeste

      return coordinates;
    };

    const handlePolygonUpdate = () => {
      try {
        if (polygonRef.current) {
          const path = polygonRef.current.getPath();
          if (path) {
            const updatedCoords = path.getArray().map((coord) => ({
              lat: coord.lat(),
              lng: coord.lng(),
            }));

            const newPolygon = new window.google.maps.Polygon({
              paths: updatedCoords,
            });

            let isOverlapping = false;

            props.pois.forEach((poi) => {
              const poiPolygon = new window.google.maps.Polygon({
                paths: poi.vertices,
              });

              // Verificación 1: Vértices del nuevo polígono dentro del POI
              for (let i = 0; i < updatedCoords.length; i++) {
                const latLng = new window.google.maps.LatLng(updatedCoords[i].lat, updatedCoords[i].lng);
                if (window.google.maps.geometry.poly.containsLocation(latLng, poiPolygon)) {
                  isOverlapping = true;
                  break;
                }
              }

              // Verificación 2: Bordes que se cruzan
              if (!isOverlapping && doPolygonsIntersect(updatedCoords, poi.vertices)) {
                isOverlapping = true;
              }

              // Verificación 3: El polígono nuevo contiene al POI
              if (!isOverlapping) {
                for (let j = 0; j < poi.vertices.length; j++) {
                  const poiLatLng = new window.google.maps.LatLng(poi.vertices[j].lat, poi.vertices[j].lng);
                  if (window.google.maps.geometry.poly.containsLocation(poiLatLng, newPolygon)) {
                    isOverlapping = true;
                    break;
                  }
                }
              }
            });

            if (isOverlapping) {
              handleSnackbarOpen('El polígono se superpone con otro.');
            } else {
              handleSnackbarClose();
            }

            if (props.onPolygonUpdate) {
              props.onPolygonUpdate(updatedCoords);
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    };


    // Función para verificar si dos polígonos se cruzan (comparando bordes)
    const doPolygonsIntersect = (poly1, poly2) => {
      for (let i = 0; i < poly1.length; i++) {
        const p1_start = poly1[i];
        const p1_end = poly1[(i + 1) % poly1.length];

        for (let j = 0; j < poly2.length; j++) {
          const p2_start = poly2[j];
          const p2_end = poly2[(j + 1) % poly2.length];

          if (doLinesIntersect(p1_start, p1_end, p2_start, p2_end)) {
            return true;
          }
        }
      }
      return false;
    };

    // Función para verificar si dos segmentos de línea se cruzan
    const doLinesIntersect = (p1, p2, q1, q2) => {
      const det = (p2.lng - p1.lng) * (q2.lat - q1.lat) - (p2.lat - p1.lat) * (q2.lng - q1.lng);

      if (det === 0) {
        return false; // Las líneas son paralelas
      }

      const lambda = ((q2.lat - q1.lat) * (q2.lng - p1.lng) + (q1.lng - q2.lng) * (q2.lat - p1.lat)) / det;
      const gamma = ((p1.lat - p2.lat) * (q2.lng - p1.lng) + (p2.lng - p1.lng) * (q2.lat - p1.lat)) / det;

      return (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
    };



    useEffect(() => {
      if (props.selectedStop) {
        setMapCenter({
          lat: props.selectedStop.latitud,
          lng: props.selectedStop.longitud,
        });
      }
    }, [props.selectedStop]);

    useEffect(() => {
      if (props.selectedStop) {
        const newPaths = generatePolygonCoordinates(
          { lat: props.selectedStop.latitud, lng: props.selectedStop.longitud },
          props.selectedStop.radius
        );
        setPolygonPaths(newPaths);

        // Solo llamamos a onPolygonUpdate si las coordenadas realmente cambiaron
        if (onPolygonUpdate) {
          onPolygonUpdate(newPaths);
        }

        if (props.showCircle && props.selectedStop) {
          let isOverlapping = false;

          // Función para verificar si dos líneas se intersectan
          const doLinesIntersect = (line1Start, line1End, line2Start, line2End) => {
            const det = (line1End.lng() - line1Start.lng()) * (line2End.lat() - line2Start.lat()) -
              (line1End.lat() - line1Start.lat()) * (line2End.lng() - line2Start.lng());

            if (det === 0) return false;  // Las líneas son paralelas

            const lambda = ((line2End.lat() - line2Start.lat()) * (line2End.lng() - line1Start.lng()) +
              (line2Start.lng() - line2End.lng()) * (line2End.lat() - line1Start.lat())) / det;
            const gamma = ((line1Start.lat() - line1End.lat()) * (line2End.lng() - line1Start.lng()) +
              (line1End.lng() - line1Start.lng()) * (line2End.lat() - line1Start.lat())) / det;

            return (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
          };

          props.pois.forEach((poi) => {
            const poiPolygon = new window.google.maps.Polygon({
              paths: poi.vertices,
            });

            // 1. Verificar si algún vértice del nuevo polígono está dentro de un POI
            for (let i = 0; i < newPaths.length; i++) {
              const latLng = new window.google.maps.LatLng(newPaths[i].lat, newPaths[i].lng);

              if (window.google.maps.geometry.poly.containsLocation(latLng, poiPolygon)) {
                isOverlapping = true;
                break;
              }
            }

            if (isOverlapping) return;

            // 2. Verificar si todos los vértices del nuevo polígono están dentro del POI (contención total)
            const allInside = newPaths.every((vertex) => {
              const latLng = new window.google.maps.LatLng(vertex.lat, vertex.lng);
              return window.google.maps.geometry.poly.containsLocation(latLng, poiPolygon);
            });

            if (allInside) {
              isOverlapping = true;
              return;
            }

            // 3. Verificar intersección de lados entre los dos polígonos
            const newPolygonPoints = [...newPaths, newPaths[0]]; // Cerramos el polígono
            const poiVertices = [...poi.vertices, poi.vertices[0]]; // Cerramos el polígono POI

            for (let i = 0; i < newPolygonPoints.length - 1; i++) {
              const newStart = new window.google.maps.LatLng(newPolygonPoints[i].lat, newPolygonPoints[i].lng);
              const newEnd = new window.google.maps.LatLng(newPolygonPoints[i + 1].lat, newPolygonPoints[i + 1].lng);

              for (let j = 0; j < poiVertices.length - 1; j++) {
                const poiStart = new window.google.maps.LatLng(poiVertices[j].lat, poiVertices[j].lng);
                const poiEnd = new window.google.maps.LatLng(poiVertices[j + 1].lat, poiVertices[j + 1].lng);

                if (doLinesIntersect(newStart, newEnd, poiStart, poiEnd)) {
                  isOverlapping = true;
                  break;
                }
              }

              if (isOverlapping) break;
            }
          });

          // Mostrar o cerrar el Snackbar según la detección de superposición
          if (isOverlapping) {
            handleSnackbarOpen('El polígono se superpone con otro.');
          } else {
            handleSnackbarClose();
          }
        } else {
          handleSnackbarClose();
        }



      }
      // Dependencias ajustadas
    }, [onPolygonUpdate, props.pois, props.selectedStop, props.showCircle]);

    // Usamos polygonPaths directamente, ya que puede estar definido en el estado

    return (
      <>
        <GoogleMap
          zoom={props.zoom}
          center={mapCenter} // Usar el estado como centro
          options={mapOptions}
          onCenterChanged={() => {
            if (mapRef.current) {
              const currentCenter = mapRef.current.getCenter();
              setMapCenter({
                lat: currentCenter.lat(),
                lng: currentCenter.lng(),
              });
            }
          }}

        >
          <MarkerClusterer>
            {props.stops.map((stop) => {
              // Si estamos convirtiendo un POI y la parada no es la seleccionada, no mostrar el marcador
              if (props.isConvertingPoi && stop.id !== props.selectedStop.id) {
                return null; // No renderiza el marcador
              }

              // Verificamos si la parada es la seleccionada
              const isSelected = props.selectedStop?.id === stop.id;

              // Cambiar el icono si la parada está seleccionada
              const markerIcon = isSelected
                ? {
                  url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',  // Ícono para parada seleccionada
                  scaledSize: new window.google.maps.Size(40, 40), // Tamaño del ícono
                }
                : {
                  url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',  // Ícono para parada no seleccionada
                  scaledSize: new window.google.maps.Size(40, 40), // Tamaño del ícono
                };

              return (
                <Marker
                  key={stop.id}
                  position={{ lat: stop.latitud, lng: stop.longitud }}
                  onClick={() => props.onMarkerClick(stop)}
                  icon={markerIcon}  // Pasamos el ícono personalizado
                />
              );
            })}
          </MarkerClusterer>
          {props.selectedStop && props.showCircle && (
            <Polygon
              ref={polygonRef}
              paths={polygonPaths}
              options={{
                fillColor: "#5dab22",
                fillOpacity: 0.45,
                strokeColor: "#5dab22",
                strokeOpacity: 1,
                strokeWeight: 3,
                draggable: true, // Permitir que el polígono sea arrastrado
                editable: true, // Permitir edición de vértices
                zIndex: 3000
              }}
              onMouseUp={handlePolygonUpdate}
              onDragEnd={handlePolygonUpdate}
            />
          )}
          {props.selectedStop && props.showCircle &&
            props.pois.map((polygon, index) => (
              <React.Fragment key={index}>
                <Polygon
                  paths={polygon.vertices}
                  options={{
                    fillColor: "#fcba03",
                    fillOpacity: 0.45,
                    strokeColor: "#8c6803",
                    strokeOpacity: 1,
                    strokeWeight: 3,
                  }}
                  onClick={() => setSelectedPolygon(polygon)}  // Manejar clic en el polígono
                />

                {selectedPolygon === polygon && (
                  <InfoWindow
                    position={{ lat: polygon.latitud, lng: polygon.longitud }}  // Muestra el InfoWindow en el primer vértice
                    onCloseClick={() => setSelectedPolygon(null)}  // Cierra el InfoWindow
                    maxWidth={300}
                  >
                    <p>{polygon.cliente}</p>
                  </InfoWindow>
                )}
              </React.Fragment>
            ))
          }


        </GoogleMap>
        {/* Snackbar */}
        <Snackbar
          open={snackbarOpen}

          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="warning">
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </>

    );
  })
);

export default MapComponent;
