import React, { useState, useEffect, useRef, useCallback } from "react";
import { Grid, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography, TextField, makeStyles, Paper, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Box, Backdrop, CircularProgress, TableSortLabel } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import config from "../../config";
import CloseIcon from '@material-ui/icons/Close';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TableContainer from '@material-ui/core/TableContainer';
import QuantityField from './QuantityField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MapComponent from "./MapComponent";
import { fetchStops, fetchPoisTypes, fetchPois, fetchSavePois, fetchSaveTipoPois } from "./ServicesAPI";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    overflowY: "auto",
  },
  tableContainer: {

    boxShadow: theme.shadows[2],
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    height: "560px",
    borderRadius: theme.shape.borderRadius,

  },
  formContainer: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    height: "450px",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.12)",
    borderRadius: 0,
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.background.paper, // Fondo para que se vea bien al hacer scroll
    zIndex: 1, // Asegúrate de que esté por encima del contenido
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: 'pointer',
  },
  centeredCell: {
    textAlign: "center",

  },
  selectedRow: {
    backgroundColor: "rgba(200, 200, 200, 0.3)", // Fondo gris claro
    borderLeft: `4px solid red`, // Borde rojo en la izquierda
    paddingLeft: "10px"
  },
  mapContainer: {
    height: "560px",
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    boxShadow: theme.shadows[2],
  },
  formHeader: {
    backgroundColor: '#f4f8fa',
    padding: theme.spacing(1, 2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    width: '100%',
    position: 'relative',
    top: 0,
    left: 0,
    right: 0,
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
  },
  formFooter: {
    backgroundColor: '#f4f8fa',
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    width: '100%',
    boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
  },
  formFields: {
    marginBottom: theme.spacing(2),
  },
  actionCell: {

    justifyContent: "flex-start",
    alignItems: "center",
  },
  actionButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",

    "@media (max-width: 1200px)": {
      flexDirection: "column", // Cambiar a columna en pantallas pequeñas
    },
  },
  button: {
    flex: "1 1", // Opcional: asegura que los botones ocupen espacio proporcional si necesario
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  muiTableCellRoot: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "250px",
  },
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}));

const daysDifference = 7;

const RecurringPois = (props) => {
  const classes = useStyles();
  const [selectedStop, setSelectedStop] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [stops, setStops] = useState([]);
  const [zoom, setZoom] = useState(17); // Estado para el zoom
  const [isConvertingPoi, setIsConvertingPoi] = useState(false);
  const [previousZoom, setPreviousZoom] = useState(17); // Estado para el zoom previo
  const [showCircle, setShowCircle] = useState(false); // Estado para controlar la visibilidad del círculo
  const [openDialog, setOpenDialog] = useState(false);
  const [newType, setNewType] = useState('');
  const [polygonPaths, setPolygonPaths] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedValue, setSelectedValue] = useState("");
  const [loading, setLoading] = useState(true);
  const [pois, setPois] = useState([]);
  const [duration, setDuration] = useState(6); // Cantidad por defectoç
  const [center, setCenter] = useState({});
  const [errors, setErrors] = useState({});

  const [order, setOrder] = useState('desc');  // Dirección de la ordenación
  const [orderBy, setOrderBy] = useState('totalParadas');  // La columna que se ordenará

  //Form Create Poi
  const [tipoPois, setTipoPois] = useState([]);
  const [namePoint, setNamePoint] = useState('');
  const [selectedValueTipoPois, setSelectedValueTipoPois] = useState(null);
  const [radius, setRadius] = useState(100); // Radio por defecto
  const [instanceMin, setInstanceMin] = useState(6);


  const rowRefs = useRef([]);
  const quantityRef = useRef(1);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      //setError(null);

      try {
        const today = new Date().toISOString().split("T")[0];
        const oneWeekAgo = new Date(Date.now() - daysDifference * 24 * 60 * 60 * 1000)
          .toISOString()
          .split("T")[0];

        setStartDate(oneWeekAgo);
        setEndDate(today);

        const data = await fetchStops(
          props.location.state.AccessToken,
          oneWeekAgo,
          today,
          6
        );

        const dataPois = await fetchPois(props.location.state.AccessToken);
        setPois(dataPois);
        setSelectedStop(data[0]);
        setCenter({ lat: data[0].latitud, lng: data[0].longitud });
        setStops(data);
      } catch (err) {
        //setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [props.location.state.AccessToken]);

  useEffect(() => {
    if (selectedStop) {
      // Verificamos si la fila seleccionada tiene referencia
      const selectedRowRef = rowRefs.current[selectedStop.id - 1]; // Asumimos que el ID es único y comienza en 1
      if (selectedRowRef) {
        selectedRowRef.scrollIntoView({
          behavior: "smooth", // Animación suave
          block: "center", // Asegura que la fila quede centrada
        });
      }
    }
  }, [selectedStop]);

  // useEffect(() => {
  //   const today = new Date();
  //   const lastWeek = new Date(today);
  //   lastWeek.setDate(today.getDate() - daysDifference); // Restamos 7 días para la fecha de inicio

  //   // Formateamos las fechas al formato YYYY-MM-DD
  //   const formattedToday = today.toISOString().split('T')[0];
  //   const formattedLastWeek = lastWeek.toISOString().split('T')[0];

  //   setEndDate(formattedToday); // La fecha de fin es la actual
  //   setStartDate(formattedLastWeek); // La fecha de inicio es una semana antes
  // }, [endDate, startDate]);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     const start = new Date(startDate);
  //     const end = new Date(endDate);

  //     // Si la fecha de fin es anterior a la de inicio, ajustamos la fecha de inicio
  //     if (end < start) {
  //       setStartDate(end.toISOString().split('T')[0]);
  //     }
  //   }
  // }, [endDate, startDate]);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     const start = new Date(startDate);
  //     const end = new Date(endDate);

  //     // Si la fecha de inicio es posterior a la de fin, ajustamos la fecha de fin
  //     if (start > end) {
  //       setEndDate(start.toISOString().split('T')[0]);
  //     }
  //   }
  // }, [endDate, startDate]);


  const handleConvert = async (stop) => {

    const dataTipoPois = await fetchPoisTypes(props.location.state.AccessToken);
    setTipoPois(dataTipoPois);

    setPreviousZoom(zoom); // Guardar el zoom previo
    setSelectedStop({ ...stop, radius }); // Añadir el radio al selectedStop
    setFormVisible(true);
    setZoom(18); // Establecer un zoom mayor cuando se selecciona una parada
    setIsConvertingPoi(true);
    setShowCircle(true); // Mostrar el círculo
  };

  const handleReject = (id) => {
    setStops((prev) => prev.filter((stop) => stop.id !== id));
  };

  const handleMarkerClick = (stop) => {
    setSelectedStop(stop);

  };

  const removeStopFromTable = (stopId) => {
    // Suponiendo que el estado de los registros es `stops`
    setStops((prevStops) => prevStops.filter(stop => stop.id !== stopId));
  };

  const handleCloseForm = () => {
    if (validateFields()) {
      setLoading(true);

      const transformedVertices = polygonPaths.map((vertex, index) => ({
        orden: index + 1,
        latitud: vertex.lat,
        longitud: vertex.lng,
      }));

      const poiData = {
        idTipo: selectedValueTipoPois.id,
        tipo: selectedValueTipoPois.tipo,
        cliente: namePoint,
        latitud: selectedStop.latitud,
        longitud: selectedStop.longitud,
        radio: radius,
        estanciaMinima: instanceMin,
        direccion: selectedStop.direccion,
        vertices: transformedVertices
      };

      const accessToken = props.location.state.AccessToken;

      fetchSavePois(accessToken, poiData)
        .then((result) => {
          // Si el guardado fue exitoso, eliminamos el registro de la tabla
          removeStopFromTable(selectedStop.id);
        })
        .catch((error) => {
          console.error("Error al enviar los datos:", error);
        })
        .finally(() => {
          // Limpiar y restaurar la interfaz
          setFormVisible(false);
          setIsConvertingPoi(false);
          setZoom(previousZoom);
          setShowCircle(false);
          setSelectedStop(null);
          setSelectedValueTipoPois(null);
          setTimeout(() => {
            setLoading(false);
          }, 1000);

        });


    }
  };

  const handleRadiusChange = (event) => {
    const newRadius = event.target.value;
    setRadius(newRadius);
    // Actualizamos el valor del radio en selectedStop
    if (selectedStop) {
      setSelectedStop({
        ...selectedStop,
        radius: newRadius
      });
    }
  };

  const handlePolygonUpdate = useCallback((newPaths) => {
    const undoButton = document.querySelector('[title="Deshacer último cambio"]');
    if (undoButton) {
      undoButton.style.display = "none"; // Ocultamos el botón de "deshacer"

    }
    setPolygonPaths(newPaths);
  }, []);

  const handleAddType = () => {
    const accessToken = props.location.state.AccessToken;

    fetchSaveTipoPois(accessToken, { tipo: newType, idIcono: selectedValue.id })
      .then((result) => {
        //console.log("Datos procesados:", result);
        let nType = {
          id: result.id,
          tipo: newType
        }

        setTipoPois([...tipoPois, nType]);
      })
      .catch((error) => {
        console.error("Error al enviar los datos:", error);
      });


    if (newType.trim()) {
      setNewType('');
      setOpenDialog(false); // Cerrar el diálogo
    }
  };

  const handleQuantityChange = (newQuantity) => {
    quantityRef.current = newQuantity; // Actualiza el valor en el ref
    //console.log('Nuevo valor de quantity:', quantityRef.current); // Puedes usar este valor donde lo necesites
    setDuration(newQuantity);
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);

    // Ajusta la fecha de fin a 7 días después de la fecha de inicio
    // const newEndDate = new Date(newStartDate);
    // newEndDate.setDate(newEndDate.getDate() + daysDifference);
    // setEndDate(newEndDate.toISOString().split('T')[0]);
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    setEndDate(newEndDate);

    // Ajusta la fecha de inicio a 7 días antes de la fecha de fin
    // const newStartDate = new Date(newEndDate);
    // newStartDate.setDate(newStartDate.getDate() - daysDifference);
    // setStartDate(newStartDate.toISOString().split('T')[0]);
  };

  const handleInstanceMinChange = (e) => {
    setInstanceMin(e.target.value);
  }

  const handleApplyFilter = async () => {
    if (!startDate || !endDate) return;

    setLoading(true);

    try {
      const data = await fetchStops(
        props.location.state.AccessToken,
        startDate,
        endDate,
        duration
      );
      setStops(data);
    } catch (err) {
      //setError(err.message);
    } finally {
      setInterval(() => {
        setLoading(false);
      }, 200);

    }
  };

  const handleCloseFormCancel = () => {
    setFormVisible(false);
    setIsConvertingPoi(false);
    setZoom(previousZoom); // Restaurar el zoom previo
    setShowCircle(false); // Mostrar el círculo
    //setSelectedStop(null);
    setSelectedValueTipoPois(null);
  }

  const handleSetNewType = (e) => {
    setNewType(e.target.value);
  }

  const handleRowClick = (stop) => {
    setSelectedStop(stop);
    setZoom(20); // Establecer un zoom mayor cuando se selecciona una parada
    //console.log(stop);
    // Llamamos a la función centerMap que recibimos como prop
    // const marker = markers.find(m => m.id === stop.id);
    // if (marker) {
    //   centerMap(marker.lat, marker.lng);  // Centramos el mapa en el marcador
    // }
  };

  // Función para manejar el cambio de orden (ascendente o descendente)
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Función para ordenar los datos de la tabla
  const handleSortData = (array, comparator) => {
    const stabilizedArray = array.map((el, index) => [el, index]);
    stabilizedArray.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedArray.map((el) => el[0]);
  };

  const comparator = (a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  };

  // Función para validar los campos
  const validateFields = () => {
    const newErrors = {};

    if (!namePoint.trim()) {
      newErrors.namePoint = 'El nombre del punto de interés es obligatorio.';
    }

    if (!selectedValueTipoPois) {
      newErrors.tipoPois = 'Debe seleccionar un tipo.';
    }

    if (!radius || radius <= 0) {
      newErrors.radius = 'El radio debe ser un número positivo.';
    }

    if (!instanceMin || instanceMin <= 0) {
      newErrors.instanceMin = 'La estancia mínima debe ser un número positivo.';
    }

    setErrors(newErrors);

    // Retorna true si no hay errores
    return Object.keys(newErrors).length === 0;
  };

  const sortedStops = handleSortData(stops, comparator);

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.header}>
          <Typography style={{ fontSize: '18px', fontWeight: 'bold' }} variant="body2">Paradas Recurrentes |</Typography>
          <Typography style={{ marginTop: '15px', fontSize: '16px', fontWeight: 'bold' }} variant="body2">
            Paradas recomendadas que deberías convertir en puntos de interés (POI)
          </Typography>
          <Typography style={{ fontStyle: 'italic', fontSize: '16px' }} variant="body2">
            Convertir las paradas recurrentes en POIs te ayuda a mejorar la gestión
            de las rutas, obtener información específica e identificar patrones
            recurrentes.
          </Typography>
          {/* <Typography style={{ marginTop: '15px', fontSize: '16px' }} variant="body2" >
          <span style={{ fontWeight: 'bold' }}>Semana 23 |</span> desde el lunes, 2 de junio hasta domingo, 8 de junio de
          2025
        </Typography> */}
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            size="medium"
            variant="outlined"
            id="start-date"
            label="Fecha inicio"
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            size="medium"
            variant="outlined"
            id="end-date"
            label="Fecha fin"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} >
          <QuantityField onChange={handleQuantityChange} />
        </Grid>
        <Grid item xs={12} md={4} >
          <Button onClick={handleApplyFilter}
            variant="outlined" size="large" style={{ height: '100%' }}>
            Aplicar
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          {formVisible && (
            <div className={classes.formHeader}>
              <Typography variant="h6">Convertir parada recurrente en POI</Typography>
              <CloseIcon className={classes.closeIcon} onClick={handleCloseFormCancel} />
            </div>
          )}
          <Paper className={formVisible ? classes.formContainer : classes.tableContainer}>
            {formVisible ? (
              <div>
                <Typography variant="body1">Localización</Typography>
                <Typography variant="body2">{selectedStop.direccion}</Typography>

                {/* Nombre del punto de interés */}
                <TextField
                  variant="outlined"
                  label="Nombre del punto de interés"
                  fullWidth
                  style={{ marginBottom: 25, marginTop: 20 }}
                  onChange={(e) => setNamePoint(e.target.value)}
                  error={!!errors.namePoint}
                  helperText={errors.namePoint}
                />

                {/* Tipo de POI */}
                <FormControl fullWidth variant="outlined" className={classes.formFields} error={!!errors.tipoPois}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Autocomplete
                      value={selectedValueTipoPois}
                      onChange={(event, newValue) => setSelectedValueTipoPois(newValue)}
                      options={tipoPois}
                      getOptionLabel={(option) => option.tipo}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tipo"
                          variant="outlined"
                          error={!!errors.tipoPois}
                        />
                      )}
                    />
                    <Button variant="text" color="primary" onClick={() => setOpenDialog(true)} style={{ whiteSpace: 'nowrap' }}>
                      [ Añadir tipo ]
                    </Button>
                  </div>
                  <FormHelperText>{errors.tipoPois}</FormHelperText>
                </FormControl>

                {/* Radio */}
                <FormControl variant="outlined" fullWidth className={classes.formFields} error={!!errors.radius}>
                  <InputLabel htmlFor="Radio">Radio</InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-weight"
                    value={radius}
                    label="Radio"
                    type="number"
                    onChange={handleRadiusChange}
                    endAdornment={<InputAdornment position="end">m</InputAdornment>}
                    inputProps={{ name: 'Radio', id: 'Radio' }}
                  />
                  <FormHelperText>{errors.radius || 'Área de acción del POI'}</FormHelperText>
                </FormControl>

                {/* Estancia mínima */}
                <FormControl variant="outlined" fullWidth className={classes.formFields} error={!!errors.instanceMin}>
                  <InputLabel htmlFor="min">Estancia mínima</InputLabel>
                  <OutlinedInput
                    fullWidth
                    id="outlined-adornment-weight"
                    value={instanceMin}
                    type="number"
                    label="Estancia mínima"
                    onChange={(e) => setInstanceMin(e.target.value)}
                    endAdornment={<InputAdornment position="end">min</InputAdornment>}
                    inputProps={{ name: 'min', id: 'min' }}
                  />
                  <FormHelperText>
                    {errors.instanceMin || 'Es el tiempo mínimo que el vehículo debe permanecer dentro del área del POI'}
                  </FormHelperText>
                </FormControl>


              </div>
            ) : (
              <TableContainer style={{ maxHeight: '550px' }}>
                <Table stickyHeader style={{ borderCollapse: "collapse" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sortDirection={orderBy === 'direccion' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'direccion'}
                          direction={orderBy === 'direccion' ? order : 'asc'}
                          onClick={() => handleRequestSort('direccion')}
                        >
                          Localización
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.centeredCell} sortDirection={orderBy === 'totalParadas' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'totalParadas'}
                          direction={orderBy === 'totalParadas' ? order : 'asc'}
                          onClick={() => handleRequestSort('totalParadas')}
                        >
                          Paradas
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.centeredCell} sortDirection={orderBy === 'totalVehiculos' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'totalVehiculos'}
                          direction={orderBy === 'totalVehiculos' ? order : 'asc'}
                          onClick={() => handleRequestSort('totalVehiculos')}
                        >
                          Vehículos
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedStops.map((stop, index) => (
                      <TableRow
                        key={stop.id}
                        className={selectedStop?.id === stop.id ? classes.selectedRow : ""}
                        ref={(el) => rowRefs.current[index] = el}
                        onClick={(e) => handleRowClick(stop)}  // Añadimos el onClick
                      >
                        <TableCell className={classes.MuiTableCellRoot}><Box>
                          {stop.direccion}
                          <Box fontSize="body2" color="textSecondary">
                            {`Lat: ${stop.latitud}, Lng: ${stop.longitud}`}
                          </Box>
                        </Box></TableCell>
                        <TableCell className={classes.centeredCell}>{stop.totalParadas}</TableCell>
                        <TableCell className={classes.centeredCell}>{stop.totalVehiculos}</TableCell>
                        <TableCell className={classes.actionCell}>
                          <Box className={classes.actionButtonsContainer} width="100%" justifyContent="space-between">
                            <Button
                              size="small"
                              onClick={() => handleConvert(stop)}
                              variant="outlined"
                              style={{ flex: 1 }} // Asegura que el botón ocupe el 100% del espacio disponible
                            >
                              Convertir
                            </Button>
                            <Button
                              size="small"
                              onClick={() => handleReject(stop.id)}
                              variant="outlined"
                              style={{ flex: 1 }} // Asegura que el botón ocupe el 100% del espacio disponible
                            >
                              Rechazar
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

            )}
          </Paper>

          {formVisible && (
            <div className={classes.formFooter}>
              <Button variant="outlined" color="default" size="small" onClick={handleCloseForm}>
                Guardar POI
              </Button>
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          <div className={classes.mapContainer}>
            <MapComponent
              stops={stops}
              selectedStop={selectedStop}
              zoom={zoom}
              isConvertingPoi={isConvertingPoi}
              pois={pois}
              onMarkerClick={handleMarkerClick}
              showCircle={showCircle}
              onPolygonUpdate={handlePolygonUpdate}
              polygonPaths={polygonPaths}
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.KeyGoogleMaps}&v=3.exp&libraries=geometry,drawing,places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </Grid>
        {/* Dialog para añadir un tipo */}
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Añadir Tipo</DialogTitle>
          <DialogContent>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                autoFocus
                margin="none"
                label="Nombre del tipo"
                variant="outlined"
                value={newType}
                onChange={handleSetNewType}
                style={{ width: 300, marginRight: '20px' }} // Espacio entre los elementos
              />
              <Autocomplete
                id="country-select-demo"
                style={{ width: 120 }}
                freeSolo={false} // Asegúrate de que sea falso para deshabilitar entrada libre
                popupIcon={null} // Elimina la flecha hacia abajo
                autoComplete={false}
                options={tiposPOI}
                value={selectedValue}
                onChange={(event, newValue) => {
                  setSelectedValue(newValue);
                }}
                getOptionLabel={() => ""} // No mostrar texto
                renderOption={(option) => (
                  <React.Fragment>
                    <img
                      src={option.image}
                      alt="Tipo POI"
                      style={{ width: 20, height: 20, display: "block", margin: "0 auto" }} // Centrar el icono
                    />
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Icono"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: selectedValue ? (
                        <InputAdornment position="start">
                          <img
                            src={selectedValue.image}
                            alt="Icono seleccionado"
                            style={{ width: 20, height: 20 }}
                          />
                        </InputAdornment>
                      ) : null,
                    }}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true, // Evita que se pueda escribir texto
                    }}
                  />
                )}
                disableClearable // Elimina la X para borrar el texto
                openOnFocus={false} // Desactiva la apertura automática al enfocar
              />

            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleAddType} color="primary">
              Añadir
            </Button>
          </DialogActions>
        </Dialog>


      </Grid>
    </>

  );
};

const tiposPOI = Array.from({ length: 86 }, (_, index) => ({
  id: (index + 1).toString(),
  label: ``,
  image: `https://comerciales.4gflota.com/iconos_web/iconosTipoPOI/imagen${index + 1}.png`,
}));

// const countries = [
//   { code: 'POI1', label: 'Tipo POI 1', image: 'https://comerciales.4gflota.com/iconos_web/iconosTipoPOI/imagen13.png' },
//   { code: 'POI2', label: 'Tipo POI 2', image: 'https://comerciales.4gflota.com/iconos_web/iconosTipoPOI/imagen14.png' },
//   { code: 'POI3', label: 'Tipo POI 3', image: 'https://comerciales.4gflota.com/iconos_web/iconosTipoPOI/imagen15.png' },
// ];
// export default withTranslation()(TemporalAccessList);
export default withRouter(compose(
  withTranslation(),
  withStyles(useStyles, { withTheme: true }),
)(RecurringPois))