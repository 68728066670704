import config from "../../config";

export const fetchStops = async (accessToken, startDate, endDate, duration) => {
  const settings = {
    method: "GET",
    headers: {
      accessToken: accessToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiGateway.URL}Stops?startDate=${startDate}T00:00:00&endDate=${endDate}T00:00:00&duration=${duration}`;

  const response = await fetch(url, settings);

  if (!response.ok) {
    throw new Error(`Error al obtener datos: ${response.status}`);
  }

  const result = await response.json();

  return result.map((item, index) => ({
    ...item,
    id: index + 1,
  }));
};

export const fetchPoisTypes = async (accessToken) => {
  const settings = {
    method: "GET",
    headers: {
      accessToken: accessToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiGateway.URL}Pois/Types`;

  const response = await fetch(url, settings);

  if (!response.ok) {
    throw new Error(`Error al obtener datos: ${response.status}`);
  }

  const result = await response.json();
  
  return result;
};

export const fetchPois = async (accessToken) => {
  const settings = {
    method: "GET",
    headers: {
      accessToken: accessToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const url = `${config.apiGateway.URL}Pois`;

  const response = await fetch(url, settings);

  if (!response.ok) {
    throw new Error(`Error al obtener datos: ${response.status}`);
  }

  const result = await response.json();

  // Transformar vértices en paths compatibles
  // const transformedPois = result.map((poi) => ({
  //   ...poi,
  //   vertices: poi.vertices.map((vertex) => ({
  //     lat: vertex.latitud,
  //     lng: vertex.longitud,
  //   })),
  // }));
  const transformedPois = result.map((poi) => ({
    ...poi,
    vertices: generatePolygonCoordinates({ lat: poi.latitud, lng: poi.longitud }, 50),
  }));
  return transformedPois;
};
const generatePolygonCoordinates = (center, radius) => {
  const coordinates = [];

  const latOffset = radius / 111320;
  const lngOffset = radius / (111320 * Math.cos(center.lat * (Math.PI / 180)));

  coordinates.push({ lat: center.lat + latOffset, lng: center.lng - lngOffset }); // Noroeste
  coordinates.push({ lat: center.lat + latOffset, lng: center.lng + lngOffset }); // Noreste
  coordinates.push({ lat: center.lat - latOffset, lng: center.lng + lngOffset }); // Sureste
  coordinates.push({ lat: center.lat - latOffset, lng: center.lng - lngOffset }); // Suroeste

  return coordinates;
};
export const fetchSavePois = async (accessToken, poiData) => {
  const settings = {
    method: "POST",
    headers: {
      accessToken: accessToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(poiData)
  };

  const url = `${config.apiGateway.URL}Pois`;

  const response = await fetch(url, settings);

  if (!response.ok) {
    throw new Error(`Error al obtener datos: ${response.status}`);
  }

  const result = await response.json();

  return result;
};

export const fetchSaveTipoPois = async (accessToken, tipoPoi) => {
  const settings = {
    method: "POST",
    headers: {
      accessToken: accessToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tipoPoi)
  };

  const url = `${config.apiGateway.URL}Pois/Types`;

  const response = await fetch(url, settings);

  if (!response.ok) {
    throw new Error(`Error al obtener datos: ${response.status}`);
  }

  const result = await response.json();

  return result;
};